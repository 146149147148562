import request from '@/utils/request'

// 获取用户列表
export function ListUser (data) {
  return request({
    url: '/sys/user/listUsers',
    method: 'POST',
    data
  })
}
// // 获取用户列表的总数
// export function ListUserCount (data) {
//   return request({
//     url: '/sys/user/listAllUsersONum',
//     method: 'POST',
//     data
//   })
// }

export function selectAllRole (data) {
  return request({
    url: '/sys/user/selectAllRoleName',
    method: 'POST',
    data
  })
}

export function deleteUser (data) {
  return request({
    url: '/sys/user/deleteUser',
    method: 'POST',
    data
  })
}
export function saveUser (data) {
  return request({
    url: '/sys/user/addUser',
    method: 'POST',
    data
  })
}

export function selctOneUser (data) {
  return request({
    url: '/sys/user/getUserInfo',
    method: 'POST',
    data
  })
}

export function getCAdminUser (data) {
  return request({
    url: '/sys/user/getCAdminUser',
    method: 'POST',
    data
  })
}

export function updateUser (data) {
  return request({
    url: '/sys/user/updateUser',
    method: 'POST',
    data
  })
}
// 查询登录用户已有的所有权限
export function selectOneRoleMenu (data) {
  return request({
    url: '/sys/role/selectOneRoleMenu',
    method: 'POST',
    data
  })
}
// 更新新自定义角色权限
export function setDiyUserAuth (data) {
  return request({
    url: '/sys/role/setDiyUserAuth',
    method: 'POST',
    data
  })
}

export function listUserByCid () {
  return request({
    url: '/sys/user/listUserByCid',
    method: 'POST'
  })
}
export function listAllRunUsers () {
  return request({
    url: '/sys/user/listAllRunUsers',
    method: 'POST'
  })
}
export function bindRunUser (data) {
  return request({
    url: '/sys/user/bindRunUser',
    method: 'POST',
    data
  })
}
export function setExpiration (data) {
  return request({
    url: '/sys/user/setExpirationDateC',
    method: 'POST',
    data
  })
}
export function getExpirationDateC (data) {
  return request({
    url: '/sys/user/getExpirationDateC',
    method: 'POST',
    data
  })
}
