<template>
  <div class="list-wrap">
    <div class="filter-wrap">
        <div>
            <label>角色</label>
            <el-select v-model="userSelect" size="small">
              <el-option key="null" label="全部" value="null">全部</el-option>
              <el-option
                v-for="item in userOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
<!--          <div>-->
<!--            <label>最后登录时间</label>-->
<!--            <el-date-picker-->
<!--              v-model="time"-->
<!--              type="date"-->
<!--              placeholder="最后登录时间">-->
<!--            </el-date-picker>-->
<!--          </div>-->
          <div>
            <label class="keyWord">关键词</label>
            <el-input v-model="keyWords" size="small" placeholder="用户名/账号/Email" />
          </div>
          <el-button type="primary" size="mini" @click="conditionSelect" :disabled="loading">查询</el-button>
        </div>
    <div class="user-list" v-loading="loading">
      <el-button type="primary" size="mini" class="add-btn" @click="handlerAdd">创建</el-button>
      <el-table
        :data="tableData"
        border max-height="calc(100% - 40px)"
        style="width: 100%">
        <el-table-column  :resizable="false"
          label="角色"
          align="center"
          width="150"
        >
          <template scope="{ row }">
            <div>{{ row.roleType }}</div>
            <div v-if="row.projectName"><el-tag type="success" size="mini">{{ row.projectName }}</el-tag></div>
          </template>
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="realName"
          label="用户名"
          align="center"
          width="150"
        />
        <el-table-column  :resizable="false"
          label="账号"
          align="center"
          width="150"
        >
          <template scope="{ row }">
            <div>{{ row.username }}</div>
          </template>
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="email"
          label="邮箱"
          align="center"
          width="200"
        />
        <el-table-column  :resizable="false"
          prop="mobile"
          label="手机号"
          align="center"
          width="120"
        />
        <el-table-column  :resizable="false"
          prop="clearTextPassword"
          label="明文密码"
          align="center"
          width="150"
        />
        <el-table-column  :resizable="false"  label="状态" align="center" width="180">
          <template scope="{ row }">
            <span v-if="row.status == 0" style="color: red;">禁用</span>
            <span v-else-if="row.status == 1" style="color: #2E8B57;">正常</span>
            <span v-else-if="row.status == 2" style="color: gray;">过期</span>
          </template>
        </el-table-column>
        <el-table-column  :resizable="false"
          label="操作"
          align="center"
        >
        <template scope="{ row }">
          <el-button type="text" v-if="row.roleType !== '终端账号'" @click="settingPermissions(row.id)">权限设置</el-button>
          <el-button type="text" v-if="row.roleType === '终端账号'" @click="settingCPermissions(row.id)">权限设置</el-button>
          <el-button type="text" v-if="row.roleType === '终端账号'" @click="setExpirationDate(row.id)">过期设置</el-button>
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleRemove(row.id,row.roleId)">删除</el-button>
        </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="设置账号到期时间"
        :visible.sync="expirationDialog"
        width="25%"
        :before-close="expirationDialogClose">
        <div v-loading="expirationDialogLoading">
        <span v-if="oldexpirationDate !== ''">账号过期时间：{{ oldexpirationDate }}</span>
          <span v-if="oldexpirationDate === ''">账号过期时间：暂未设置</span>
        <el-date-picker
          style="width: 100%;margin-top: 20px"
          v-model="expirationDate"
          type="datetime"
          placeholder="选择日期"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="expirationDialog = false">取 消</el-button>
    <el-button type="primary" @click="confSetExpirationDate">确 定</el-button>
  </span>
      </el-dialog>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10 , 20, 30, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      top="30vh"
      width="30%"
      :before-close="handleClose">
      <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delectUsers">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { ListUser, selectAllRole, deleteUser, setExpiration, getExpirationDateC } from '@/api/settings/userManagement'
export default {
  data () {
    return ({
      id: '',
      cid: 0,
      expirationDialog: false,
      expirationDate: '',
      oldexpirationDate: '',
      expirationDialogLoading: true,
      loading: false,
      tableData: [],
      userOptions: [],
      userSelect: '',
      time: '',
      keyWords: '',
      dialogVisible: false,
      limit: 10,
      currentPage: 1,
      count: 0,
      roleId: ''
    })
  },
  methods: {
    async setExpirationDate (id) {
      this.id = id
      this.expirationDialog = true
      this.oldexpirationDate = ''
      this.expirationDialogLoading = true
      const res = await getExpirationDateC({ id: id })
      if (res.msg !== null) {
        this.oldexpirationDate = res.msg.substr(0, 19)
      }
      this.expirationDialogLoading = false
    },
    async confSetExpirationDate () {
      const res = await setExpiration({ id: this.id, expirationDate: this.expirationDate })
      if (res.code === 0) {
        this.expirationDialog = false
        this.$message.success('设置成功')
      } else {
        this.$message.error('设置失败')
      }
    },
    expirationDialogClose () {
      this.expirationDialog = false
    },
    handlerAdd () {
      this.$router.push('/settings/userManagement/add/' + this.cid)
    },
    handleRemove (id, roleId) {
      this.id = id
      this.roleId = roleId
      this.dialogVisible = true
    },
    delectUsers () {
      this.delectUser(this.id, this.roleId)
    },
    handleClose () {
      this.dialogVisible = false
    },
    handleSizeChange (val) {
      this.limit = val
      this.listAlluser(val, this.currentPage)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.listAlluser(this.limit, val)
    },
    settingPermissions (id) {
      this.$router.push('/settings/userManagement/settingPermissions/' + this.$route.params.id + '/' + id + '/0')
    },
    settingCPermissions (id) {
      this.$router.push('/settings/userManagement/settingPermissions/' + this.$route.params.id + '/' + id + '/1')
    },
    handleEdit (row) {
      this.$router.push(`/settings/userManagement/edit/${row.roleId}/${row.id}`)
    },
    async listAlluser (size, number, userSelect, keyWords) {
      this.loading = true
      try {
        if (this.$route.meta.type === 'editCusUser') {
          const res = await ListUser({ size: size, number: number, roleId: userSelect, keyword: keyWords, cid: this.cid })
          this.tableData = res.data.list
          this.count = res.data.total
        } else {
          const res = await ListUser({ size: size, number: number, roleId: userSelect, keyword: keyWords })
          this.tableData = res.data.list
          this.count = res.data.total
        }
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async selectAllRole () {
      try {
        const res = await selectAllRole()
        this.userOptions = res.data
      } catch (err) {
        console.log(err)
      }
    },
    async delectUser (id, roleId) {
      try {
        const res = await deleteUser({ id: id, roleId: roleId })
        if (res.code === 0) {
          this.dialogVisible = false
          // this.$router.push('/settings/userManagement')
          this.$message.success('删除成功')
          this.listAlluser(this.limit, this.currentPage)
        } else {
          this.$message.error('删除失败')
        }
      } catch (err) {
        this.$message.error('删除失败')
        console.log(err)
      }
    },
    conditionSelect () {
      this.listAlluser(this.limit, this.currentPage, this.userSelect, this.keyWords)
    }
  },
  mounted () {
    this.cid = this.$route.params.id
    this.listAlluser(this.limit, this.currentPage)
    this.selectAllRole()
    eventHub.$emit('msg', '用户管理')
    this.cid = this.$route.params.id
  },
  beforeDestroy () {
    eventHub.$off('cid')
  },
  computed: {
    // 监听数据
    getCid () {
        eventHub.$on('cid', value => {
          this.cid = value
        })
      return this.cid
    }
  },
  watch: {
    //  监听改变事件
    getCid: {
      handler (newValue, oldValue) {
        this.limit = 10
        this.currentPage = 1
        this.listAlluser(this.limit, this.currentPage)
      }
    }
  }
}
</script>

<style lang="scss">
.list-wrap {
  width: 100%;
  // margin-left: 30px;
  .filter-wrap {
    width: 100%;
    height: 88px;
    background: #fff;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    padding: 20px;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius:2px;
    div {
      display: flex;
      font-size: 12px;
      align-items: center;
      margin-right: 10px;
      label {
        margin-right: 10px;
      }
      .keyWord {
        width: 55px;
      }
    }
  }
  .user-list {
    width: 100%;
    min-height: calc(100% - 103px);
    background: #fff;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    .add-btn {
      margin-bottom: 20px;
    }
    .el-table {
      margin-bottom: 50px;
      .el-button {
        padding: 0;
        margin-left: 0;
      }
      .el-table__body-wrapper {
        .cell {
          .el-button {
            margin-right: 10px;
            cursor: pointer;
            &:hover{
              color: skyblue;
            }
          }
        }
      }
    }
    .el-pagination {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
  .el-dialog {
    .prompt {
      height: 28px;
      display: flex;
      align-items: center;
      .el-icon-warning {
        font-size: 28px;
        color: #FF8B35;
        margin-right: 12px;
      }
    }
  }
}
</style>
